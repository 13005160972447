/* footer css start */
.footer {
    height: 10vh;
    display: flex;
    flex-direction: row;
    bottom: 0;
    left: 0;
}

.footer-left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0em 2em;
    margin: 0em 2em;
    border-radius: 25px 25px 0px 0px;
}

.footer-right {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4em;
    margin: 0em 2em;
    border-radius: 25px 25px 0px 0px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.popup {
    cursor: pointer;
    transition: all 0.3s ease;
}

.popicon-title {
    color: #ffffff;
}

.popuptext {
    position: absolute;
    min-width: 150px;
    max-width: 350px;
    white-space: pre-wrap;
    color: #ffffff;
    bottom: 12vh;
    border: 2px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);
    border-radius: 15px;
    padding: 15px;
    margin: 15px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.info {
    left: 50px;
}

.popup:hover .popuptext {
    opacity: 1;
    visibility: visible;
}

.social-media {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    color: #ffffff;
}

.social-media p {
    text-decoration: none;
    cursor: pointer;
    color: var(--white);
    transition: all .3s;
}

.social-media p:hover {
    color: rgba(255, 255, 255, 0.781);
}

@media screen and (max-width: 1000px) {
    .popicon-title {
        display: none;
    }
}

/* For Safari on iOS devices */
@media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio:0) and (pointer:coarse), (max-width: 768px) and (-webkit-min-device-pixel-ratio:0) and (-webkit-min-device-pixel-ratio: 2) {
    .popuptext {
        bottom: 50vh;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        justify-content: center;
        width: 100%;
    }

    .footer-right {
        display: none;
    }

    .footer-left {
        width: 100%;
        border-radius: 25px;
    }

    .popicon {
        width: 30px;
    }

    .popicon-title {
        display: none;
    }
    
    .social-media a {
        text-decoration: none;
        cursor: pointer;
        color: var(--white);
    }

    .popuptext {
        font-size: 12px;
        bottom: 12vh;
    }

    .stay {
        right: 50px;
    }

    .shutter {
        left: 50px;
    }
}
/* footer css end */