/* Global CSS */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --max-width: 1440px;
  --primary: #CCC9DC;
  --black: #000000;
  --white: #ffffff;
}

* {
  padding: 0;
  margin: 0;
}

body {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    scroll-behavior: smooth;
    position: fixed;
    width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
/* Globle CSS Ends */