#header {
  padding: 10px;
  color: #000000;
  font-size: 26px;
  font-weight: bold;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
}

.calenderBack{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -99;
  filter: blur(2px);
}

.calender-container {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: 98;
}

.calander-slide {
  display: flex;
  gap: 5px;
}

.calander-slide p {
  cursor: pointer;
  display: flex;
  width: 30px;
  height: 30px;
  border: var(--white);
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all .5s;
}

.calander-slide p:hover{
  background-color: #7c7c7c7c;
}

#container {
  position: absolute;
  width: 560px;
}

#weekdays {
  display: flex;
  color: #ffffff;
}

#weekdays div {
  display: flex;
  justify-content: center;
  width: 70px;
  margin: 5px;
}

#calendar {
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

#header {
  color: var(--white);
}

.day {
  width: 70px;
  padding: 10px;
  height: 70px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  margin: 5px;
  box-shadow: 0px 0px 5px #CBD4C2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all .5s;
}

.day:hover {
  background-color: rgba(255, 102, 0, 0.884);
}
  
.day + .currentDay {
  border: 1px solid var(--white);
}

.day + .highlighted {
  /*background-color: rgb(211, 120, 120);*/
}

.calander-slide {

}

.highlighted {
  z-index: 98;
}
 
.padding {
  cursor: default !important;
  background-color: #FFFCFF !important;
  box-shadow: none !important;
}

.highlightedText {
  font-size: 8px;
 
}

.highlight-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-text {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 250px;
  height: auto;
  background-color: rgba(255, 102, 0, 0.884);
  color: white;
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  margin: 20px;
  opacity: 0;
  transition: all 0.5s;
}

.highlightedIcon {
  width: 30px;
  height: 30px;
}

.tooltip-text h4 {
  font-size: 10px;
}

.tooltip-text p {
  font-size: 10px;
}

.day:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

 
@media screen and (min-width: 1500px) {
  #container {
    position: absolute;
    width: 630px;
  }
  .day {
    font-size: 14px;
    width: 80px;
    height: 80px;
  }
  #weekdays div {
    width: 80px;
  }
  .highlightedText {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  #container {
    position: absolute;
    width: 322px;
  }
  .day {
    font-size: 8px;
    width: 40px;
    height: 40px;
    margin: 3px;
  }
  #weekdays div {
    width: 40px;
  }
  .highlightedText {
    display: none;
  }
  .highlightedIcon {
    width: 15px;
    height: 15px;
  }
  .tooltip-text {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 120px;
    height: auto;
    background-color: rgba(255, 102, 0, 0.884);
    color: white;
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    opacity: 0;
    transition: all 0.5s;
  }
  .tooltip-text h4 {
    font-size: 8px;
  }
  
  .tooltip-text p {
    font-size: 7px;
  }
}