/* background css start */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(3px);
    z-index: 1;
}

.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -99;
}
/* background css end */

/* Content css start */
.content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: calc(100vh - 20vh);
    overflow: hidden;
}

.popuptext.stay {
    max-height: 400px; /* Set a maximum height */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
    padding: 10px; /* Add some padding for readability */
    box-sizing: border-box; /* Include padding in width/height calculations */
}

.popuptext.stay {
    width: auto; /* Allow dynamic width */
    word-wrap: break-word; /* Break long words to avoid overflow */
}

.slider::-webkit-scrollbar {
    width: 2px;
    height: 0px;
  }
  
  .slider::-webkit-scrollbar-track {
    border-radius: 50px;
  }
  
  .slider::-webkit-scrollbar-thumb {
    border-radius: 50px;
    height: 10px;
  }
  
.audio-container {
    height: 100%;
    min-width: 200px;
    max-height: 400px;
    padding: 10px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 25px 0px 0px 25px;
    transition: right 0.3s ease;
    right: 0;
}

.hide-audio-container {
    right: -300px;
}

.slide-container {
    height: 100%;
    position: relative;
}

.icon-container {
    position: absolute;
    display: flex;
    top: 50%;
    left: -10px;
    font-size: 50px;
    transform: translateY(-50%);
    padding: 10px 0px;
    border-radius: 15px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    color: rgb(255, 255, 255);
    transition: right 0.2s ease;
}

.hide-icon-container {
    left: 150px;
    right: -300px;
}

.audioicon {
    width: 30px;
    padding-bottom: 20px;
    transition: opacity 0.3s ease;
}

.playbutton {
    cursor: pointer;
}

.audio-controler {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content button {
    display: none;
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--white);
    cursor: pointer;
    border-radius: 50px;
    outline: none;
    height: 35px;
    width: 35px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}

.content button:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
}

.volume-slider {
    width: 80px;
    background-color: white;
    border: none;
    border-radius: 10px;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
}

.volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -4px;
}
  
.volume-slider::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 6px;
    background-color: #ddd;
    border-radius: 5px;
}

.calender-close {
    position: absolute;
    border: none;
    outline: none;
    display: flex;
    font-size: 25px;
    top: 25px;
    right: 25px;
    cursor: pointer;
    border-radius: 50px;
    color: var(--white);
    z-index: 99;
    transition: all .5s;
}

.calender-close:hover {
    background-color: rgba(209, 209, 209, 0.185);
}

.space {
    height: 10vh;
}

@media screen and (min-width: 1500px) {
    .audio-container {
        max-height: 600px;
    }
}

/* For Safari on iOS devices */

@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 768px) {
    .content {
        height: 70vh;
    }
}
  

@media screen and (max-width: 768px) {
    .hide-audio-container {
        right: 0px;
    }
    .icon-container {
        display: none;
    }
    
    .hide-icon-container {
        display: none;
    }

    .content {
        justify-content: space-around;
    }

    .content button {
        display: flex;
    }
    
    .audio-container {
        max-height: 400px;
        border-radius: 25px;
    }
}

@media only screen and (max-width: 768px) {
    .desktop-only {
      display: none;
    }
}

@media only screen and (min-width: 768px) {
    .mobile-only {
      display: none;
    }
}

/* Additional styles specific to Apple devices */
@supports (-webkit-touch-callout: none) {
    @media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 768px) {
        .volume-slider {
            display: none;
        }

        .icon-items {
            padding: 10px;
            border: 1px solid var(--white);
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.5);
        }
        
        .audioicon.icon-items.paused {
            opacity: 0.7;
            background-color: rgba(126, 126, 126, 0.5);
        }
    }
}
/* Content css end */
