/* header css start */
.header {
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;
    height: 10vh;
    margin: 0em 2em;
}

.header .social-media {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
}

.logo-container {
    width: fit-content;
    height: 100%;
}

.logo {
    width: auto;
    height: 50%;
    padding: 1em 2em;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0px 0px 25px 25px;
}

.navigation-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.navigation-buttons button {
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--white);
    cursor: pointer;
    border-radius: 50px;
    outline: none;
    height: 35px;
    width: 35px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation-buttons button:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
}

@media screen and (max-width: 768px) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logo-container {
        margin: 0em;
    }
    .navigation-buttons {
        display: none;
    }

    .header .social-media {
        display: block;
    }
}
/* header css end */