.popup-container {
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-message {
  position: relative;
  padding: 25px;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  width: 500px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 99;
}

.popup-message h2 {
  font-size: 18px;
}


.popup-message p {
  font-size: 16px;
}

.popup-message .calender-close {
  position: absolute;
  border: none;
  outline: none;
  display: flex;
  font-size: 25px;
  top: 25px;
  right: 25px;
  cursor: pointer;
  border-radius: 50px;
  color: var(--white);
  z-index: 99;
  transition: all .5s;
}

.popup-message .calender-close:hover {
  background-color: rgba(209, 209, 209, 0.185);
}

@media screen and (max-width: 768px) {
  .popup-message {
    position: relative;
    padding: 25px;
    color: var(--white);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    width: 300px;
    min-height: 250px;
    z-index: 99;
  }
}